import React from "react"
import { Box, Text, Container, Link, Flex, Heading } from "@chakra-ui/react"
import { graphql, Link as GatsbyLink } from "gatsby"

import Seo from "../components/seo"
import HomeBackground from "../components/HomePage/HomeBackground"
import SidebarNav from "../components/SidebarNav/SidebarNav"
import MainContentBox from "../components/MainContentBox/MainContentBox"
import TwentyYearsStrong from "../components/HomePage/TwentyYearsStrong"
import MobileNav from "../components/MobileNav/MobileNav"
import Footer from "../components/Footer/Footer"
import NewsletterBlock from "../components/NewsletterSignupBlock/NewsletterSignupBlock"
import SliceZone from "../components/SliceZone/SliceZone"
import { ChevronRightIcon, ChevronLeftIcon } from "@chakra-ui/icons"
import { getImage } from "gatsby-plugin-image"

export const query = graphql`
query BlogPostQuery($uid: String) {
  prismicBlogPost(uid: {eq: $uid}) {
    uid
    tags
    data {
      body {
        ... on PrismicBlogPostDataBodyBlogPostContent {
          id
          slice_type
          items {
            post_content {
              raw
            }
          }
        }
        ... on PrismicBlogPostDataBodyImageGallery {
          id
          slice_type
          items {
            image_caption {
              text
            }
            image {
              localFile {
                childImageSharp {
                  thumb: gatsbyImageData(
                    width: 270
                    height: 270
                    placeholder: BLURRED
                    )
                  full: gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
            image_caption {
              text
            }
          }
        }
      }
      post_title {
        text
      }
      post_excerpt {
        text
      }
      post_category
      featured_image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              quality: 60
              layout: FULL_WIDTH
              outputPixelDensities: 1.5
              placeholder: BLURRED
            )
          }
        }
      }
      publish_date(formatString: "MMMM, D YYYY")
    }
  }
}
`

const Post = ({ location, data, pageContext }) => {
  const pageLocation = location.pathname
  // Get pageContext object from gatsby-node.js
  const { prev, next } = pageContext

  if (!data) return null
  const document = data.prismicBlogPost.data

  // Define the Post content returned from Prismic
  const pubDate = data.prismicBlogPost.data.publish_date
  const postTags = data.prismicBlogPost.tags
  
  //const backgroundImage = data.allPrismicPage.edges[0].node.data.background_image.localFile

  return (
    <>
      <Seo title="Valley Advocates For Responsible Development" />
      <HomeBackground>
        {/* <Box  display={{ sm: 'none', md: 'block' }} >
            <Header {...props} />
          </Box> */}
        <Container maxW="container.xl">
          {/* <StickyHeader /> */}
          <MobileNav />
          <Box paddingTop="20" width="100%">
            <SidebarNav 
              pathName={pageLocation}
            />

            <MainContentBox>
              <Box
                mb={{ base: "-9px", md: "-15px" }}
                ml="10px"
                maxW={{ sm: "320px", md: "600px" }}
                
              >
                <TwentyYearsStrong width="100%" />
              </Box>
              <Box
                backgroundColor="rgba(255, 255, 255, 0.8)"
                padding={{ sm: 4}}
              >
                <Box 
                //padding={{ sm: 4, md: 12, lg: 16 }}
                pb="0">
                  <Heading
                    as="h1"
                    color="red.400"
                    textAlign="left"
                    marginBottom="2"
                    pt="8"
                    
                    fontSize={{ sm: "1.5rem", md: "2rem" }}
                  >
                    {document.post_title.text}
                  </Heading>

                  <Flex justifyContent="space-between" px="8">
                    <Box mb="4" id="pub-date">
                      <Text>{pubDate}</Text>
                      </Box>
                    <Box>                      
                          <Flex>
                            <Text mr="2">Categories:</Text>
                            {postTags.map((postTag, index) => {
                              return (
                                <>
                              <Box>
                                <Text key={index}>
                                  { (index ? ', ' : '') + postTag }
                                </Text>
                              </Box> 
                              </>
                              )
                            })}
                          </Flex>
                       <Box />
                    </Box>
                  </Flex>
                </Box>

                <SliceZone sliceZone={document.body} />

                <Flex
                  py="8"
                  px="4"
                  justifyContent="space-between"
                  flexWrap="wrap"
                  textAlign="center"
                  
                >
                  <Box mb={{ sm: 4, md: 0 }}>
                    {prev && (
                      <Link
                        to={`/news/${prev.uid}`}
                        as={GatsbyLink}
                        variant="blog-link"
                      >
                        <ChevronLeftIcon fontSize="1.5rem" />
                        {prev.data.post_title.text}
                      </Link>
                    )}
                  </Box>
                  <Box mb={{ sm: 4, md: 0 }}>
                    {next && (
                      <Link
                        to={`/news/${next.uid}`}
                        as={GatsbyLink}
                        variant="blog-link"
                      >
                        {next.data.post_title.text}
                        <ChevronRightIcon fontSize="1.5rem" />
                      </Link>
                    )}
                  </Box>
                </Flex>
              </Box>

              <Box>
                <NewsletterBlock />
              </Box>
              <Footer />
            </MainContentBox>
          </Box>
        </Container>
      </HomeBackground>
    </>
  )
}

export default Post
